* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: #fbbb34;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  width: 100%;
}

button {
  padding: 0;
  border: 0;
  background: 0;
  outline: none;
  cursor: pointer;
}

p {
  margin: 0;
}
