.Title {
  text-align: center;
  margin-top: 20px;
}

.Title--hidden {
  opacity: 0;
}

.Logo {
  background-color: #fbbb34;
  width: 200px;
}

.Scroll-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition: 300ms ease opacity;
}

.Scroll-button svg {
  width: 20px;
  height: 20px;
}

.Scroll-button--hidden {
  opacity: 0;
  pointer-events: none;
}

.Cover-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Title--fixed {
  position: fixed;
  background-color: #fbbb34;
  top: 0;
  padding: 20px 0 5px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
}

.Content {
  padding: 20px;
  font-size: 18px;
  position: relative;
  text-align: center;
}

.Zoe {
  width: 40px;
  position: relative;
  top: 3px;
}

.Content-fader {
  background: linear-gradient(#fbbb34 10%, transparent 90%);
  height: 50px;
  position: absolute;
  z-index: 2;
  width: 100%;
}

.Image-wrap {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
}

.Image-item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Facts {
  padding: 0 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Facts-title {
  margin: 0;
  margin-bottom: 20px;
}

.Facts-fact {
  text-align: center;
}

.Facts-button {
  width: 15px;
  height: 15px;
  margin-left: 3px;
  position: relative;
  top: 1px;
}

.Videos {
  overflow: hidden;
  position: relative;
}

.Videos-wrapper {
  transition: 300ms ease transform;
  display: flex;
}

.Video {
  flex-shrink: 0;
  width: calc(100% - 100px);
  margin: 0 50px;
  position: relative;
  padding-bottom: 56.25%;
}

.Video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Videos-controls {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.Videos-controls svg {
  width: 20px;
  height: 20px;
}

.Video-arrow--hidden {
  opacity: 0;
  pointer-events: none;
}

.Video-arrow--left {
  z-index: 1;
  transform: rotate(90deg);
}

.Video-arrow--right {
  z-index: 1;
  transform: rotate(-90deg);
}

.Authors {
  text-align: justify;
  font-style: italic;
}

.Potpis {
  text-align: center;
}

.Authors p {
  position: relative;
}

.Authors-button {
  position: relative;
  top: 3px;
  left: 20px;
}

.Authors-button--top {
  top: 0;
  transform: rotate(180deg);
}

.Authors-button svg {
  width: 20px;
  height: 20px;
}

.Tadija2 {
  display: none;
}

.Tadija2--visible {
  display: block;
}

.Social {
  margin: 30px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 20px;
}

.Social a {
  margin: 0 5px;
}

.Social svg {
  width: 30px;
  height: 30px;
}

@media (min-width: 768px) {
  .Image-wrap {
    padding-bottom: 0;
    width: 500px;
    height: 500px;
  }

  .Content {
    padding: 0;
    width: 500px;
    margin: 0 auto;
    font-size: 24px;
  }

  .Facts-fact {
    width: 500px;
    margin: 0 auto;
  }

  .Zoe {
    width: 50px;
  }

  .Videos {
    width: 540px;
    margin: 0 auto;
  }
}

@media (min-width: 1024px) {
  .Image-wrap {
    padding-bottom: 0;
    width: 750px;
    height: 750px;
  }

  .Content {
    width: 750px;
  }

  .Facts-fact {
    width: 750px;
  }

  .Videos {
    width: 790px;
    margin: 0 auto;
  }
}
